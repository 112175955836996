import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

export default ({ data }) => (
	<Layout>
		<SEO title="General Electric — Ryan Teuscher" />
		<nav>
			<Link to="/">
				<span className="material-icons">arrow_back</span>
			</Link>
		</nav>
		<header>
			<h1>General Electric. Refining GE’s healthcare design extension.</h1>
			<h3>Client of Waybury &mdash; Co-Founder & Director of Product Design</h3>
			<p>
				GE’s Healthcare Design Extension (HDx) is a subset of the company’s
				billion-dollar Industrial Internet Design initiative. GE needed a revamp
				of their Healthcare Design Extension to follow conventions laid out in
				the IIDx, tightening up its visual design and cleaning up the code
				structure. Our design focus was not to rethink the HDx, but to refine it
				by removing visual fragmentation and bring out the best of a solid
				design system through refinement and testing.
			</p>
		</header>
		<section>
			<figure>
				<Image alt="GE documentation" filename="slide-ge-item.png" />
			</figure>
		</section>
	</Layout>
)
